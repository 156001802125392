import styles from './App.module.scss';
import MainArea from "./components/mainArea/mainArea";
import phoneIcon from './assets/telephone.png';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 id={styles.header}>Workiz Dialer Hub<img src={phoneIcon}/> </h1>
      </header>
        <MainArea/>
    </div>
  );
}

export default App;
