import {useState} from "react";
import styles from './addDialer.module.scss';

const envs = ['app.workiz.com','staging.workiz.com'];

const AddDialer = ({addDialer}) => {
    const [isDropDownOpen,setIsDropDownOpen] = useState(false);
    const [customEnv, setCustomEnv] = useState('');
    const openDropDown = () => {
        setIsDropDownOpen(true);
    }
    const onChange = (e) => {
        addEnv(e.target.value);
    }

    const onCustomEnvChange = (e) => {
        setCustomEnv(e.target.value);
    }

    const addEnv = (url) => {
        addDialer(url);
        setIsDropDownOpen(false)
    }

    return (
        <div className={styles.wrapper} onClick={openDropDown}>
            <span  className={styles.addButton}>+</span>
            {isDropDownOpen &&
                <>
                    <select defaultValue={'default'} onClick={e => e.preventDefault()} onChange={onChange}>
                        <option value={'default'}>choose env</option>
                        {envs.map(url => <option value={url}>{url}</option>)}
                    </select>
                    <p>or add custom env</p>
                    <div>
                        <input onChange={onCustomEnvChange}/>
                        <button onClick={() => addEnv(customEnv)}>add</button>
                    </div>
                </>
            }
        </div>
    )
};

export default AddDialer;
