import styles from './dialer.module.scss';

const Dialer = ({url, deleteDialer}) => {

    return (
        <div className={styles.wrapper}>
            <p className={styles.deleteDialer} onClick={deleteDialer}>X</p>
            <p>{url}</p>
            <iframe  allow="camera; microphone" src={'https://'+url+"/dialer/"}/>
        </div>
    )
}

export default Dialer;