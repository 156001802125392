import {useEffect, useState} from "react";
import AddDialer from "../addDialer/addDialer";
import Dialer from "../dialer/dialer";
import styles from './mainArea.module.scss';

const MainArea = ({}) => {
    const [dialers,setDialers] = useState([]);
    useEffect(() => {
        const config = localStorage.getItem('dialersUrls');
        if(config !== '' && config){
            setDialers(config?.split(','));
        }

    }, [])
    const addDialer = (url) => {
        setDialers([...dialers, url]);
    }

    const saveConfig = () => {
        localStorage.setItem('dialersUrls', dialers);
    }

    const deleteConfig = () => {
        localStorage.removeItem('dialersUrls');
        setDialers([]);
    }
    
    const deleteDialer = (index) => {
        const _dialers = [...dialers];
        _dialers.splice(index, 1);
        setDialers(_dialers);
    }

    return (
        <div className={styles.content}>
            <span className={styles.buttons}>
                <button onClick={saveConfig}>save configuration</button>
                <button onClick={deleteConfig}>clear</button>
            </span>
            <div className={styles.wrapper}>
                {dialers.map((url, index) => <Dialer url={url} deleteDialer={()=>deleteDialer(index)}/> )}
                <AddDialer addDialer={addDialer}/>
            </div>
        </div>

    )
};

export default MainArea;